import React from 'react';

import MainLayout from '@components/Layout';


function ThankYou() {
  return (
    <MainLayout>
        {/* <meta http-equiv="refresh" content="2; url=http://localhost:8000"></meta> */}
     
    <form method="GET" action="https://eohyi5op4r0m6zp.m.pipedream.net/" >
      ...
      <label>
        Email
        <input type="text" id="name" name="name" />
      </label>
      <label>
      desc
        <input type="text" id="desc" name="desc" />
      </label>
      <label>
      presence
        <input type="text" id="presence" name="presence" />
      </label>
      <label>
        Person
        <input type="text" id="person" name="person" />
      </label>
      ...
      <input type="submit" value="Submit"/>
    </form>

      <h1>thank yiuu ❤️</h1>
    </MainLayout>
  );
}

export default ThankYou;